import FullScreenFallbackLayout from 'components/layout/FullScreenFallbackLayout';
import Whoops from 'components/Whoops';
import { useRouteError } from 'react-router-dom';

import classes from './RouteError.module.scss';

export default function RouteError() {
    const error = useRouteError();

    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error('Uncaught error:', error);
    }

    // TODO: Catch and send to Sentry?
    /**
     * Right now this still acts as an ErrorBoundry but we can get more specific when we
     * start using data loaders. We can throw more specific errors e.g. "Application
     * with id <ID> not found".
     */

    return (
        <FullScreenFallbackLayout>
            <Whoops message="Something went wrong">
                <div className="content">
                    <p>
                        Looks like something went wrong that we can&lsquo;t recover from.
                        <br />
                        If the issue continues, please contact the dev team.
                    </p>

                    {error instanceof Error && process.env.NODE_ENV === 'development' ? (
                        <div className={classes['error-msg']}>
                            <pre>{error.stack}</pre>
                        </div>
                    ) : null}

                    <p>
                        <a className={classes.reset} href="/">
                            Reset Application
                        </a>
                    </p>
                </div>
            </Whoops>
        </FullScreenFallbackLayout>
    );
}
