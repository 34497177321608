import { type AddressValues, transformPlaceResultToAddressValues } from '@keyliving/utils';
import { useCallback, useEffect, useRef } from 'react';

/**
 * NOTE: A prerequisite to using this hook is to add Googles inline bootstrap
 * loader tag to enable the Dynamic Library Import API to the index.html. For
 * an example, look at the resident-hub/public/index.html
 */

/**
 * Hook that uses the Google Maps Autocomplete to attach a dropdown
 * to a text input and once a suggestion is selected, pass back
 * the prettified details of the selected address.
 */
export default function useAddressAutoComplete(
    onAutoComplete: (addressValues: AddressValues) => void,
    options?: google.maps.places.AutocompleteOptions | null
) {
    const autoCompleteRef = useRef<google.maps.places.Autocomplete>();
    const inputRef = useRef<HTMLInputElement | null>(null);

    const setupAutoComplete = useCallback(
        async (input: HTMLInputElement) => {
            const { Autocomplete } = (await google.maps.importLibrary(
                'places'
            )) as google.maps.PlacesLibrary;

            autoCompleteRef.current = new Autocomplete(input, options);

            autoCompleteRef.current.addListener('place_changed', () => {
                const place = autoCompleteRef.current?.getPlace();

                if (place) {
                    const resolvedPlace = transformPlaceResultToAddressValues(place);
                    onAutoComplete(resolvedPlace);
                }
            });
        },
        [onAutoComplete, options]
    );

    useEffect(() => {
        if (inputRef.current) {
            setupAutoComplete(inputRef.current);
        }
    }, [setupAutoComplete, inputRef]);

    return { inputRef };
}
