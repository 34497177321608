import { createContext, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface UnsavedChangesContextData {
    hasUnsavedChanges: boolean;
    setHasUnsavedChanges: (value: boolean) => void;
}

interface ProviderProps {
    children: ReactNode;
    defaultIsDirty?: boolean;
}

const defaultState: UnsavedChangesContextData = {
    hasUnsavedChanges: false,
    setHasUnsavedChanges: () => {},
};

const UnsavedChangesContext = createContext<UnsavedChangesContextData>(defaultState);

export function UnsavedChangesProvider({ children, defaultIsDirty = false }: ProviderProps) {
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(defaultIsDirty);
    const { pathname, search } = useLocation();

    /**
     * Using window.location.href doesn't cause a re-render that we can "react" to with useEffect.
     * Using useLocation means we get a new location object causing a re-render and can compare
     * the urls and see if we are truly on a new page/tab
     */
    const relativePath = `${pathname}${search}`;

    useEffect(() => {
        /**
         * If we have unsaved changes and have successfully changed locations then
         * it's because the user agreed to navigate, or we haven't properly
         * blocked them from navigating.
         */
        setHasUnsavedChanges(false);
    }, [relativePath]);

    return (
        <UnsavedChangesContext.Provider value={{ hasUnsavedChanges, setHasUnsavedChanges }}>
            {children}
        </UnsavedChangesContext.Provider>
    );
}

export default UnsavedChangesContext;
