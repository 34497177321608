import { WarningIcon } from '@keyliving/component-lib';

import useUnsavedChanges from '../useUnsavedChanges';
import classes from './UnsavedChangesIndicator.module.scss';

export default function UnsavedChangesIndicator() {
    const { hasUnsavedChanges } = useUnsavedChanges();

    if (hasUnsavedChanges) {
        return (
            <div className={classes.unsaved}>
                <WarningIcon height={18} width={18} /> Unsaved changes
            </div>
        );
    }

    return null;
}
