import '@keyliving/component-lib/dist/css/global.css';

import { ToastProvider } from '@keyliving/component-lib';
import ErrorBoundary from 'components/ErrorBoundary';
import HydrationGate from 'components/HydrationGate';
import PrivateRoute from 'components/PrivateRoute';
import RouteError from 'components/RouteError';
import { UnsavedChangesProvider } from 'components/UnsavedChanges';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { authRoutes, publicRoutes } from 'routes/routes';

import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter(
    [
        ...publicRoutes,
        {
            /**
             * Our authRoutes are wrapped in the PrivateRoute component so if a user
             * navigates to them and is not logged in, they are redirected to the
             * login page vs getting a 404 if we didn't create them.
             */
            path: '/',
            element: (
                <UnsavedChangesProvider>
                    <PrivateRoute />
                </UnsavedChangesProvider>
            ),
            errorElement: <RouteError />,
            children: authRoutes,
        },
    ],
    {
        future: {
            v7_relativeSplatPath: true,
        },
    }
);

render(
    <StrictMode>
        <ErrorBoundary>
            <ToastProvider>
                <HydrationGate>
                    <RouterProvider
                        future={{
                            v7_startTransition: true,
                        }}
                        router={router}
                    />
                </HydrationGate>
            </ToastProvider>
        </ErrorBoundary>
    </StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
