import { Button, Modal } from '@keyliving/component-lib';

import classes from './UnsavedChangesPrompt.module.scss';

interface UnsavedChangesPromptProps {
    onCancel: VoidFunction;
    onProceed: VoidFunction;
    showModal: boolean;
}

export default function UnsavedChangesPrompt({
    onCancel,
    onProceed,
    showModal,
}: UnsavedChangesPromptProps) {
    return (
        <Modal
            onClose={() => {
                onCancel();
            }}
            showModal={showModal}
        >
            <div className="text-center content">
                <h3>You have unsaved changes</h3>
                <p>Are you sure you want to leave?</p>
            </div>

            <div className={classes.actions}>
                <Button
                    fill="outline"
                    onClick={(e) => {
                        e.stopPropagation();
                        onCancel();
                    }}
                    size="sm"
                    theme="destructive"
                    type="button"
                >
                    Close
                </Button>
                <Button
                    fill="outline"
                    onClick={(e) => {
                        e.stopPropagation();
                        onProceed();
                    }}
                    size="sm"
                    theme="primary"
                    type="button"
                >
                    Proceed
                </Button>
            </div>
        </Modal>
    );
}
