import { object, string } from 'zod';

export default function useEnv() {
    const envSchema = object({
        REACT_APP_API_SERVICE_ENDPOINT: string().min(1),
        REACT_APP_IDENTITY_SERVICE_ENDPOINT: string().min(1),
        REACT_APP_OWNER_PORTAL_URL: string().min(1),
        REACT_APP_OWNER_PORTAL_LOGIN_URL: string().min(1),
        REACT_APP_RESIDENT_PORTAL_URL: string().min(1),
        REACT_APP_RESIDENT_PORTAL_LOGIN_URL: string().min(1),
        REACT_APP_APPLICATION_FORM_URL: string().min(1),
        REACT_APP_APPLICATION_FORM_LOGIN_URL: string().min(1),
        REACT_APP_SLP_URL: string().min(1),
        REACT_APP_SLP_LOGIN_URL: string().min(1),
        REACT_APP_ONBOARDING_URL: string().min(1),
        REACT_APP_ONBOARDING_LOGIN_URL: string().min(1),
        REACT_APP_RESIDENT_HUB_URL: string().min(1),
        REACT_APP_RESIDENT_HUB_LOGIN_URL: string().min(1),
    });

    return envSchema.parse(process.env);
}
