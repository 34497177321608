import { IconProps } from './types';

export default function WarningIcon({ height = 24, width = 24, ...rest }: IconProps) {
    return (
        <svg
            fill="none"
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            {...rest}
        >
            <path
                d="M13.204 3.085a1.375 1.375 0 0 0-2.408 0l-8.937 16.5a1.375 1.375 0 0 0 1.203 2.04h17.875a1.374 1.374 0 0 0 1.204-2.04l-8.937-16.5ZM12 14.75V8.562"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
            <path
                d="M12 18.188a.344.344 0 1 1 0-.688M12 18.188a.344.344 0 1 0 0-.688"
                stroke="currentColor"
                strokeWidth="2"
            />
        </svg>
    );
}
