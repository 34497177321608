import { CountryCode } from '@keyliving/shared-types';
import { type AddressValues, mergeRefs } from '@keyliving/utils';
import { forwardRef } from 'react';

import { useAddressAutoComplete } from '../../hooks';
import TextInput, { type TextInputProps } from '../TextInput';

export type AddressAutocompleteProps = TextInputProps & {
    /** Called when a use selects a location from the suggestions */
    onPlaceChanged: (value: AddressValues) => void;
    /** Restrict suggestions to a country(ies) */
    restrictToCountryCode?: CountryCode | CountryCode[] | null;
};

/**
 * NOTE: A prerequisite to using this component is to add Googles inline bootstrap
 * loader tag to enable the Dynamic Library Import API to the index.html. For an
 * example, look at the resident-hub/public/index.html
 */

const AddressAutocomplete = forwardRef<HTMLInputElement, AddressAutocompleteProps>(
    ({ onPlaceChanged, restrictToCountryCode = ['CA'], ...rest }, ref) => {
        const { inputRef: autoCompleteInputRef } = useAddressAutoComplete(onPlaceChanged, {
            componentRestrictions: {
                country: restrictToCountryCode,
            },
            fields: ['address_components'],
            types: ['address'],
        });

        return <TextInput ref={mergeRefs([autoCompleteInputRef, ref])} {...rest} />;
    }
);

export default AddressAutocomplete;
